// Grid breakpoints
$grid-breakpoints: (
  xs: 0,
  sm: 768px,
  md: 1024px,
  lg: 1440px,
  xl: 1680px,
  xxl: 1920px,
);

// Grid containers
$container-max-widths: (
  md: calc(100% - (100% / 12 * 2) * 0.83333),
);

// Grid columns
$grid-columns: 12;
$grid-gutter-width: 0.5rem;
$grid-row-columns: 6;

// Container padding
$container-padding-x: $grid-gutter-width;

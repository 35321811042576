/* element */
.mm-badge {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: auto;
  height: 1rem;
  padding: 0 0.3125rem;
  color: $ecru-05;
  user-select: none;
  background-color: $ecru-95;
  border-radius: $border-radius-2;
  @include type('global-label-expressive-1');

  /* modifier */
  &.-rounded {
    padding: 0 0.375rem;
    border-radius: $border-radius-full;
  }

  &.-md {
    height: 1.25rem;
    padding: 0 0.375rem;
    border-radius: $border-radius-2;
    @include type('global-label-expressive-2');

    &.-rounded {
      padding: 0 0.5rem;
      border-radius: $border-radius-full;
    }
  }

  &.-light {
    color: $ecru-95;
    background-color: get-color('ecru-95', 10);
  }

  &.-outline {
    color: $ecru-95;
    background-color: transparent;
    border: 1px solid $ecru-95;
  }

  &.-ecru-05 {
    color: $ecru-95;
    background-color: $ecru-05;

    &.-light {
      color: $ecru-05;
      background-color: get-color('ecru-05', 10);
    }

    &.-outline {
      color: $ecru-05;
      background-color: transparent;
      border: 1px solid $ecru-05;
    }
  }

  @each $variant in 'info', 'success', 'warning', 'danger' {
    &.-#{$variant} {
      background-color: get-color(#{$variant + '-50'});

      &.-light {
        color: get-color(#{$variant + '-90'});
        background-color: get-color(#{$variant + '-50'}, 10);
      }

      &.-outline {
        color: get-color(#{$variant + '-50'});
        background-color: transparent;
        border-color: get-color(#{$variant + '-50'});
      }
    }
  }

  &.-blur {
    color: $ecru-05;
    background-color: get-color('ecru-05', 40);

    @include supports-backdrop-filter {
      background-color: get-color('ecru-95', 10);
      backdrop-filter: blur(200px);
    }
  }
}

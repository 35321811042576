@mixin button-reset {
  padding: 0;
  font-family: inherit;
  font-style: initial;
  font-weight: inherit;
  color: inherit;
  text-decoration: inherit;
  cursor: pointer;
  background-color: transparent;
  border: 0;
  outline: 0;
  appearance: none;

  &:hover,
  &:active,
  &:focus,
  &:visited {
    color: inherit;
  }
}

@mixin list-reset {
  padding: 0;
  margin: 0;
  list-style: none;

  li {
    padding: 0;
    margin: 0;
  }
}

.cart-page {
  // MARG-694
  // padding: 1rem 0;
  padding: 1rem 0.25rem;

  &.empty-cart-page {
    min-height: calc(100vh - 8rem);
  }

  .column-sidecart {
    margin-left: rem(100);
    min-width: rem(277);
  }

  .row-cart-content {
    > div {
      padding: 0;
    }
  }
  .js-lineitem {
    .cart-item-grid {
      position: relative;
      .cart-edit-link {
        position: absolute;
        top: -1px;
        right: 30px;
      }
    }
  }

  .fullscreen-modal .modal-container {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

/* stylelint-disable selector-no-qualifying-type */

/* element */
.mm-form--needs-validation,
.mm-form--validated {
  .mm-form-input {
    input {
      background-repeat: no-repeat;
      background-position: right 1rem center;
      background-size: 1.125rem 1.125rem;
    }
  }
}

/* states */
.mm-form--needs-validation {
  .mm-form-message.-valid,
  .mm-form-message.-invalid {
    display: none;
  }
}

.mm-form-input.-touched,
.mm-form-check.-touched {
  input:not(:focus):invalid,
  input:not(:focus).-invalid {
    border-color: $danger-50;
    ~ label {
      color: $danger-50;
    }
  }
}

.mm-form-textarea.-touched {
  textarea:not(:focus):invalid,
  textarea:not(:focus).-invalid {
    border-color: $danger-50;
    ~ label {
      color: $danger-50;
    }
  }
}

.mm-form-select.-touched {
  select:not(:focus):invalid,
  select:not(:focus).-invalid {
    border-color: $danger-50;
    ~ label {
      color: $danger-50;
    }
  }
}

.mm-form {
  .mm-form-input {
    input {
      background-repeat: no-repeat;
      background-position: right 1rem center;
      background-size: 1.125rem 1.125rem;
    }
  }
}

.mm-form-input:not(.-invalid) {
  input:not(:placeholder-shown):valid,
  input.-valid {
    background-image: $form-input-check;
  }
}

.mm-form--needs-validation {
  .mm-form-input {
    .no-background {
      background-image: none !important;
    }
    .toggle-password {
      display: none;
      &.visible {
        display: flex;
      }
      &:hover {
        background-color: transparent;
      }
    }
  }
}

//input:invalid ~ .mm-form-message.-invalid,
input.-invalid ~ .mm-form-message.-invalid,
input:valid ~ .mm-form-message.-valid,
input.-valid ~ .mm-form-message.-valid,
//textarea:invalid ~ .mm-form-message.-invalid,
textarea.-invalid ~ .mm-form-message.-invalid,
textarea:valid ~ .mm-form-message.-valid,
textarea.-valid ~ .mm-form-message.-valid,
//select:invalid ~ .mm-form-message.-invalid,
select.-invalid ~ .mm-form-message.-invalid,
select:valid ~ .mm-form-message.-valid,
select.-valid ~ .mm-form-message.-valid {
  display: flex;
}

input:valid ~ .mm-form-message.-invalid,
input.-valid ~ .mm-form-message.-invalid,
input:invalid ~ .mm-form-message.-valid,
input.-invalid ~ .mm-form-message.-valid,
textarea:valid ~ .mm-form-message.-invalid,
textarea.-valid ~ .mm-form-message.-invalid,
textarea:invalid ~ .mm-form-message.-valid,
textarea.-invalid ~ .mm-form-message.-valid,
select:valid ~ .mm-form-message.-invalid,
select.-valid ~ .mm-form-message.-invalid,
select:invalid ~ .mm-form-message.-valid,
select.-invalid ~ .mm-form-message.-valid {
  display: none;
}

.mm-form-input,
.mm-form-textarea,
.mm-form-select {
  &.-invalid {
    .mm-form-message.-invalid {
      display: flex;
    }
  }
  & ~ .mm-form-message {
    margin-top: 1rem;
  }
}

@mixin type($token, $modifier: null) {
  @if not map-has-key($types, $token) {
    @error "The type token: '#{$token}' does not exist.";
  }

  $type: map-get($types, $token);
  $base-props: map-get($type, base);
  $breakpoints: map-get($type, breakpoints);

  @each $prop, $value in $base-props {
    @if $value != null and $prop != 'font-weight' {
      #{$prop}: $value;
    }
    @if $prop == 'font-weight' {
      @if $modifier == 'bold' {
        font-weight: #{$value + 100};
      } @else {
        font-weight: $value;
      }
    }
  }

  @each $breakpoint-key, $breakpoint-props in $breakpoints {
    @include media-breakpoint-up($breakpoint-key) {
      @each $prop, $value in $breakpoint-props {
        @if $value != null {
          #{$prop}: $value;
        }
      }
    }
  }
}

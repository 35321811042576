.mm-color-swatch {
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 28px;
  height: 28px;
  border-radius: 9px;
  appearance: none;

  &__inner {
    display: inline;
    width: 16px;
    height: 16px;

    background-repeat: no-repeat;
    background-position: center center;
    background-size: 100% 100%;
    border-radius: 5px;
    appearance: none;
    border: 1px solid $ecru-40;

    &.-active,
    &:checked {
      box-shadow: 0 0 0 4px $ecru-05, 0 0 0 6px $info-50;

      &:focus {
        box-shadow: 0 0 0 4px $ecru-05, 0 0 0 6px $info-50, 0 0 0 10px get-color('info-50', 30);
      }
    }
  }

  /* states */
  [type='radio']:not(:checked) {
    cursor: pointer;
  }
  &:focus-within,
  [type='radio']:focus {
    outline: 0;
  }

  /* modifier */
  &.-sm {
    width: 18px;
    height: 18px;
    border-radius: 6px;

    .mm-color-swatch__inner {
      width: 8px;
      height: 8px;
      border-radius: 2px;

      &:checked {
        box-shadow: 0 0 0 3px $ecru-05, 0 0 0 5px $info-50;

        &:focus {
          box-shadow: 0 0 0 3px $ecru-05, 0 0 0 5px $info-50, 0 0 0 8px get-color('info-50', 30);
        }
      }
    }
  }

  &.-lg {
    width: 36px;
    height: 36px;
    border-radius: 12px;

    .mm-color-swatch__inner {
      width: 24px;
      height: 24px;
      border-radius: 6px;
    }
  }

  &.-xl {
    width: 44px;
    height: 44px;
    border-radius: 14px;

    .mm-color-swatch__inner {
      width: 32px;
      height: 32px;
      border-radius: 8px;
    }
  }
}

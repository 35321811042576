/* element */
.mm-form-fieldset {
  display: flex;
  flex-direction: column;
  padding: 0;
  margin: 0;
  gap: 1rem;

  legend {
    padding: 0;
    margin: 0 0 1rem;
  }

  /* grid overrides */
  .row {
    --mm-gutter-x: 1rem;
  }
}

/* element */
.mm-switch {
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 3rem;
  padding: 0.25rem 0.125rem;
  color: $ecru-95;
  background-color: get-color('ecru-95', 10);
  border-radius: $border-radius-8;

  &__toggle {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 2.5rem;
    padding: 0 1.5rem;
    margin: 0 0.125rem;
    white-space: nowrap;
    cursor: pointer;
    user-select: none;
    background: none;
    border-radius: $border-radius-6;
    @include type('global-control-productive-3');
    &.-active {
      background-color: $white;
      box-shadow: $box-shadow-sm;
    }
  }

  /* modifier */
  &.-sm {
    height: 2rem;
    border-radius: $border-radius-6;

    .mm-switch__toggle {
      height: 1.5rem;
      @include type('global-control-productive-1');
      border-radius: $border-radius-4;
    }
  }

  &.-md {
    height: 2.5rem;
    border-radius: $border-radius-7;

    .mm-switch__toggle {
      height: 2rem;
      @include type('global-control-productive-2');
      border-radius: $border-radius-5;
    }
  }
}

$border-radius-1: 0.125rem; // 2px
$border-radius-2: 0.25rem; // 4px
$border-radius-3: 0.375rem; // 6px
$border-radius-4: 0.5rem; // 8px
$border-radius-5: 0.625rem; // 10px
$border-radius-6: 0.75rem; // 12px
$border-radius-7: 0.875rem; // 14px
$border-radius-8: 1rem; // 16px
$border-radius-9: 1.125rem; // 18px
$border-radius-10: 1.25rem; // 20px
$border-radius-11: 1.375rem; // 22px
$border-radius-12: 1.5rem; // 24px
$border-radius-full: 9999px;

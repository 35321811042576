/* element */
.mm-notification {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: auto;
  height: 3rem;
  color: $ecru-95;
  background-color: get-color('ecru-05', 20);
  border-radius: $border-radius-full;
  box-shadow: 0 24px 96px rgba(0, 0, 0, 0.24);

  @include supports-backdrop-filter {
    backdrop-filter: blur(200px);
  }

  &__message {
    @include type('global-control-productive-3');
    padding: 0 1.5rem;
  }

  &__actions {
    display: flex;
    align-items: center;
    padding: 0 0.25rem;

    .mm-button + .mm-button {
      margin-left: 0.25rem;
    }
  }

  /* modifier */

  &.-sm {
    height: 2rem;

    .mm-notification__message {
      @include type('global-control-productive-1');
      padding: 0 0.75rem;
    }
  }

  &.-md {
    height: 2.5rem;

    .mm-notification__message {
      @include type('global-control-productive-2');
      padding: 0 1.125rem;
    }
  }

  &.-ecru-05 {
    color: $ecru-05;
    background-color: get-color('ecru-95', 60);
  }
}

/* element */
.mm-form-check {
  display: inline-flex;
  flex-wrap: wrap;
  align-items: center;

  label {
    display: inline-flex;
    flex-direction: column;
    line-height: inherit;
  }

  [type='checkbox'],
  [type='radio'] {
    align-self: center;
    width: $form-check-size-md;
    height: $form-check-size-md;
    vertical-align: middle;

    /* states */
    cursor: pointer;
    border: 2px solid get-color('ecru-95', 10);
    border-radius: $form-check-size-md-radius;
    box-shadow: 0 0 0 4px transparent;
    transition-timing-function: ease-out;
    transition-duration: 75ms;
    transition-property: box-shadow background-color background-position;
    appearance: none;

    &:hover:not(:focus) {
      &:not(:disabled) {
        &[indeterminate],
        &:checked {
          @include media-hover {
            background-color: get-color('info-60');
            border-color: transparent;
          }
        }
      }

      &:not(:checked):not(:disabled):not([indeterminate]) {
        @include media-hover {
          cursor: pointer;
          border-color: get-color('ecru-95', 20);
        }
      }
    }

    &:checked {
      background-color: get-color('info-50');
      background-image: $form-check-icon-md;
      border-color: transparent;
    }

    &:disabled {
      cursor: not-allowed;
      border-color: get-color('ecru-95', 20);
    }

    &:disabled:checked {
      background-color: get-color('ecru-95', 20);
      border-color: transparent;
    }

    &:active:not(:checked):not(:disabled) {
      background-color: get-color('ecru-95', 10);
    }

    &:active:checked:not(:disabled) {
      background-color: get-color('info-70');
      border-color: transparent;
    }

    &:focus {
      border-color: $info-50;
      outline: 0;
      box-shadow: 0 0 0 4px get-color('info-50', 20);
    }

    &[indeterminate]:not(:checked) {
      background-color: get-color('info-50');
      background-image: $form-check-icon-md-indeterminate;
      border-color: get-color('info-50');
    }

    &[indeterminate]:active:not(:checked) {
      background-color: get-color('info-70');
    }
  }

  [type='radio'] {
    border-radius: $border-radius-full;
  }

  /* modifiers */
  &.-lg {
    [type='checkbox'],
    [type='radio'] {
      width: $form-check-size-lg;
      height: $form-check-size-lg;

      &:checked {
        background-image: $form-check-icon-lg;
      }
      &[indeterminate]:not(:checked) {
        background-image: $form-check-icon-lg-indeterminate;
      }
    }

    [type='checkbox'] {
      border-radius: $form-check-size-lg-radius;
    }
  }

  &.-xl {
    [type='checkbox'],
    [type='radio'] {
      width: $form-check-size-xl;
      height: $form-check-size-xl;

      &:checked {
        background-image: $form-check-icon-xl;
      }
      &[indeterminate]:not(:checked) {
        background-image: $form-check-icon-xl-indeterminate;
      }
    }

    [type='checkbox'] {
      border-radius: $form-check-size-xl-radius;
    }
  }
}

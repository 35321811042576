/* Arguments
 * $ratio: '2x3', '9x16',  
 * $variant: 1 to 10
 */

@mixin mask($ratio, $variant) {
  clip-path: url(#mask-#{$ratio}-#{$variant});
  -webkit-clip-path: url(#mask-#{$ratio}-#{$variant});

  /* Safari hack: Cliping only works if the 
   * direct child has a transform applied */
  & > * {
    transform: translateZ(0);
  }
}

$pdp-accordion-bg: rgba(17, 17, 17, 0.06);
/* Écru 05 Transparent/50 */
$ecru-50-50: rgba(247, 247, 242, 0.5);
/* Écru 95 Transparent/10 */
$ecru-95-10: rgba(17, 17, 17, 0.06);
/* Écru 05 Transparent/60 */
$ecru-05-60: rgba(247, 247, 242, 0.64);
/* Écru 05 Transparent/95 */
$ecru-05-95: rgba(247, 247, 242, 0.95);
/* Écru 95 Transparent/20 */
$ecru-95-20: rgba(17, 17, 17, 0.14);
/* Écru 95 Transparent/40 */
$ecru-95-40: rgba(17, 17, 17, 0.36);
/* Écru 95 Transparent/50 */
$ecru-95-50: rgba(0, 0, 0, 0.42);
/* Écru Dark Transparent/20 */
$ecru-dark-20: rgba(17, 17, 17, 0.25);
/* Écru Light Transparent/20 */
$ecru-light-20: rgba(247, 247, 242, 0.25);

$info-transparent-10: rgba(31, 105, 255, 0.06);

$info-30-24: rgb(107, 166, 255, 0.24);

$green-text: #40a975;
$grey-notify: #696966;

$header-height: 3.5rem;
$header-height-mdmin: 3rem; // IBT-3745
$menu-v-padding: 1rem;

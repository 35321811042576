// PDP Image Carousel Thumbs
.swiper.thumbnails {
  .swiper-wrapper {
    .swiper-slide {
      &.product-slide-thumb-video {
        .shakamediaplayer {
          border-radius: 0.5rem;
          top: 0;
          position: absolute;
          transform: translate(-50%);

          &.thumbnail {
            object-fit: cover;
            max-width: 75px;
            height: 100%;
          }
        }
      }
    }
  }
}

// PDP Product Carousel Main
.swiper.product-carousel-main {
  .swiper-slide {
    height: auto;
    &.product-slide {
      .swiper-zoom-container {
        height: 100%;
      }

      .mask-container,
      .shakamediaplayer {
        max-width: 360px;
        width: 100%;
        object-fit: cover;
        height: 100%;

        @media (min-width: 1600px) {
          max-width: 540px;
        }
      }
    }
  }
}

// Wishlist
.product-tile--wishlist {
  .image-container {
    .mm-video-card {
      .mask-container,
      .shakamediaplayer {
        height: 100%;
        object-fit: cover;
      }
    }
  }
}

// PLP
.product-brand.mm-product-card {
  .product-tile {
    .image-container {
      .mm-video-card {
        .mask-container,
        .shakamediaplayer {
          height: 100%;
          object-fit: cover;
        }
      }
    }
  }
}

// Page Designer

.shakamediaplayer {
  &.pagedesigner {
    height: 100%;
    object-fit: cover;
  }
}

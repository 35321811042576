$opacity-10: 0.06;
$opacity-20: 0.14;
$opacity-30: 0.24;
$opacity-40: 0.36;
$opacity-50: 0.5;
$opacity-60: 0.64;
$opacity-70: 0.76;
$opacity-80: 0.86;
$opacity-90: 0.94;

$opacity-values: (
  10: $opacity-10,
  20: $opacity-20,
  30: $opacity-30,
  40: $opacity-40,
  50: $opacity-50,
  60: $opacity-60,
  70: $opacity-70,
  80: $opacity-80,
  90: $opacity-90,
);

.icon {
  border: none;
  min-width: initial;
  padding: 0;
  height: auto;
  @each $name, $uri in $sprites {
    &--#{$name} {
      @extend .icon;
      @include fixedSprite($name);
      > span {
        display: none;
      }
    }
  }
}

/* element */
.mm-form-toggle {
  --width: 2.75rem;
  --height: 1.5rem;
  --gap: 0.125rem;
  --active-size: 1.625rem;
  --transform-checked: calc(var(--height) - (var(--gap) * 2));
  --transform-checked-active: calc(
    var(--transform-checked) - (var(--active-size) - var(--height)) -
      (var(--gap) * 2)
  );

  > [type='checkbox'] {
    position: relative;
    display: inline-flex;
    width: var(--width);
    height: var(--height);
    cursor: pointer;
    background-color: get-color('ecru-95', 20);
    border-radius: $border-radius-full;
    box-shadow: 0 0 0 4px transparent;
    transition: background-color 175ms ease;
    transition-timing-function: ease-out;
    transition-duration: 75ms;
    transition-property: box-shadow;
    appearance: none;

    &::after {
      position: absolute;
      top: var(--gap);
      left: var(--gap);
      width: calc(var(--height) - (var(--gap) * 2));
      height: calc(var(--height) - (var(--gap) * 2));
      content: '';
      background-color: $ecru-05;
      border-radius: $border-radius-full;
      box-shadow: $box-shadow-sm;
      transition-timing-function: ease;
      transition-duration: 175ms;
      transition-property: transform width;
      transform: translateX(0);
      transform-origin: left;
    }

    /* states */
    &:active:not(:checked):not(:disabled) {
      &::after {
        width: var(--active-size);
        transform: translateX(0);
      }
    }

    &:focus {
      outline: 0;
      box-shadow: 0 0 0 4px get-color('info-50', 20);
    }

    &:checked {
      background-color: $info-50;

      &::after {
        transform: translateX(var(--transform-checked));
      }

      &:active:not(:disabled)::after {
        width: var(--active-size);
        transform: translateX(var(--transform-checked-active));
      }
    }

    &:disabled {
      cursor: not-allowed;

      &::after {
        background-color: $ecru-10;
      }
      &:checked {
        &::after {
          background-color: $info-20;
        }
      }
    }
  }

  /* modifiers */
  &.-sm {
    --width: 1.75rem;
    --height: 1rem;
    --active-size: 1rem;
  }

  &.-lg {
    --width: 3.75rem;
    --height: 2rem;
    --active-size: 2.25rem;
  }
}

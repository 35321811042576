/* element */
.mm-form-select {
  position: relative;

  select {
    width: 100%;
    height: 3rem;
    padding: 0 3rem 0 1rem;
    color: $ecru-95;
    cursor: pointer;
    user-select: none;
    background-color: $ecru-05;
    background-image: $form-select-chevron;
    background-repeat: no-repeat;
    background-position: right 1rem center;
    background-size: 1rem 1rem;
    border: 0.125rem get-color('ecru-95', 10) solid;
    border-radius: $border-radius-7;
    box-shadow: 0 0 0 4px transparent;
    transition-timing-function: ease-out;
    transition-duration: 75ms;
    transition-property: box-shadow border-color;
    appearance: none;
    @include type('global-control-productive-3');
    line-height: normal;

    &:hover:not(:focus) {
      @include media-hover {
        border-color: get-color('ecru-95', 20);
      }
    }

    &:focus ~ label {
      color: $info-50;
    }

    &:focus {
      border-color: $info-50;
      outline: 0;
      box-shadow: 0 0 0 4px get-color('info-50', 20);
    }
  }

  /* modifier */
  &.-md {
    select {
      height: 2.5rem;
      padding: 0 2.25rem 0 0.75rem;
      background-position: right 0.75rem center;
      background-size: 0.75rem 0.75rem;
      line-height: normal;
    }
  }

  &.-light {
    select {
      background-color: get-color('ecru-95', 10);
      border-color: transparent;

      &:hover:not(:focus) {
        @include media-hover {
          background-color: get-color('ecru-95', 20);
          border-color: transparent;
        }
      }

      &:focus {
        border-color: $info-50;
      }
    }
  }

  &.-floating-label {
    select {
      &:focus ~ label {
        color: $info-50;
      }
    }

    label {
      position: absolute;
      top: 0;
      left: 1rem;
      z-index: 2;
      padding: 0 0.1875rem;
      color: get-color('ecru-95', 30);
      pointer-events: none;
      background-color: inherit;
      background-color: $ecru-05;
      transition-timing-function: ease-out;
      transition-duration: 75ms;
      transition-property: font-size top;
      transform: translate3d(0, -50%, 0);
      @include type('global-label-productive-3');
    }
  }
}

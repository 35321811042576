.wishlist-page {
  .wishlistItemCards {
    margin: 0 auto;
    min-width: calc(100% - 12.8rem);
    width: min(100%, 1440px - 12.8rem);

    > .row {
      gap: 0;
      // MARG-658
      row-gap: 4rem;
    }
  }

  .spacing-tiles {
    .col {
      padding-right: 15px;
      padding-left: 15px;
    }
  }

  .add-to-cart-add-to-wishlist {
    margin-left: 0.5rem;
    margin-right: 0.5rem;

    .mm-switch {
      height: 2.5rem;

      &__toggle,
      .select-size,
      .add-to-cart {
        height: 2rem;
      }
    }

    .select-size {
      font-size: 13px;
      line-height: 16px;
    }
  }

  .single-element-content-detail-description {
    font-size: 14px;
  }

  .single-element-content-detail-price {
    font-size: 13px;
  }

  .add-to-wish-list {
    color: $ecru-95;
    font-size: 12px;
    line-height: 15px;
  }
}

#wishlist-show {
  .wishlistItemCards {
    margin-top: 4rem;
  }
}

#wishlist-accountshow {
  #account-section-header {
    margin-bottom: 3.5rem;
  }

  .help-title {
    font-size: 20px;
  }

  .wishlistItemCards {
    margin-top: 0;
  }
}

/* element */
.mm-form-input,
.mm-form--needs-validation .mm-form-input {
  position: relative;

  input {
    width: 100%;
    height: 3rem;
    padding: 0 1rem;
    color: $ecru-95;
    background-color: $ecru-05;
    border: 0.125rem get-color('ecru-95', 10) solid;
    border-radius: $border-radius-7;
    box-shadow: 0 0 0 4px transparent;
    transition-timing-function: ease-out;
    transition-duration: 75ms;
    transition-property: box-shadow border-color;
    appearance: none;
    @include type('global-control-productive-3');

    &::placeholder,
    &::-webkit-datetime-edit-text {
      color: get-color('ecru-95', 30);
    }

    &::-webkit-calendar-picker-indicator {
      display: none;
    }

    /* input states */
    &:focus {
      border-color: $info-50;
      outline: 0;
      box-shadow: 0 0 0 4px get-color('info-50', 20);
    }

    &:hover:not(:focus) {
      border-color: get-color('ecru-95', 20);
    }
  }

  label {
    color: $ecru-95;
  }

  /* modifier */
  &.-floating-label {
    input {
      &::placeholder {
        opacity: 0;
      }

      /* states */
      &:focus ~ label {
        color: $info-50;
      }
      &:focus ~ label,
      &:not(:placeholder-shown) ~ label {
        top: 0;
        @include type('global-label-productive-3');
      }
    }

    label {
      position: absolute;
      top: 1.5rem;
      left: 1rem;
      z-index: 2;
      padding: 0 0.1875rem;
      color: get-color('ecru-95', 30);
      pointer-events: none;
      background-color: inherit;
      background-color: $ecru-05;
      transition-timing-function: ease-out;
      transition-duration: 75ms;
      transition-property: font-size top;
      transform: translate3d(0, -50%, 0);
      @include type('global-control-productive-3');
    }
  }
}

/* element */
.mm-icon {
  position: relative;
  display: inline-flex;
  width: 1rem;
  height: 1rem;
  color: currentColor;

  svg {
    width: 100%;
    height: 100%;

    *[stroke-width],
    *[strokeWidth] {
      vector-effect: non-scaling-stroke;
      stroke-width: 1.375px;
    }
  }

  /* modifier */

  &.-xl {
    width: 1.25rem;
    height: 1.25rem;

    svg *[stroke-width],
    svg *[strokeWidth] {
      stroke-width: 1.675px;
    }
  }

  &.-lg {
    width: 1.125rem;
    height: 1.125rem;

    svg *[stroke-width],
    svg *[strokeWidth] {
      stroke-width: 1.5px;
    }
  }

  &.-sm {
    width: 0.875rem;
    height: 0.875rem;

    svg *[stroke-width],
    svg *[strokeWidth] {
      stroke-width: 1.25px;
    }
  }

  &.-xs {
    width: 0.75rem;
    height: 0.75rem;

    svg *[stroke-width],
    svg *[strokeWidth] {
      stroke-width: 1.125px;
    }
  }
}

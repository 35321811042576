/* stylelint-disable selector-no-qualifying-type */

/* element */
.mm-form-message {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  padding: 0.5rem 1rem;
  border: 2px solid transparent;
  border-radius: $border-radius-7;
  @include type('global-small-2');

  input ~ &,
  textarea ~ &,
  select ~ & {
    margin-top: 1rem;
  }

  /* modifier */
  &.-invalid {
    color: $danger-50;
    background-color: get-color('danger-60', 10);
  }

  &.-valid {
    color: $success-50;
    background-color: get-color('success-60', 10);
  }

  &.-info {
    color: $info-60;
    background-color: get-color('info-60', 10);
  }

  &.-info-grey {
    color: $info-grey;
    background-color: get-color('info-60', 10);
  }
}

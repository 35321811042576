// Apply custom type classes
@each $token, $type in $types {
  $base-props: map-get($type, base);
  $breakpoints: map-get($type, breakpoints);

  .#{'type-' + $token} {
    @each $prop, $value in $base-props {
      @if $value != null {
        #{$prop}: $value;
      }
    }

    @each $breakpoint-key, $breakpoint-props in $breakpoints {
      @include media-breakpoint-up($breakpoint-key) {
        @each $prop, $value in $breakpoint-props {
          @if $value != null {
            #{$prop}: $value;
          }
        }
      }
    }

    strong,
    .type-bold,
    &.-bold {
      font-weight: #{map-get($base-props, 'font-weight') + 100};
    }
  }
}

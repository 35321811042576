/* element */
.mm-menu-item {
  @include button-reset;
  position: relative;
  display: flex;
  align-items: center;
  height: 2rem;
  padding: 0 0.8125rem;
  color: $ecru-95;
  user-select: none;
  background-color: transparent;
  border: 2px solid transparent;
  border-radius: $border-radius-5;
  box-shadow: 0 0 0 4px transparent;
  transition-timing-function: ease-out;
  transition-duration: 75ms;
  transition-property: box-shadow border-color;
  @include type('global-control-productive-3');

  span:first-child {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  /* states */
  &:focus-visible {
    border-color: $info-50;
    outline: 0;
    box-shadow: 0 0 0 4px get-color('info-50', 20);
  }

  &:not(:disabled) {
    &:hover {
      @include media-hover {
        background-color: get-color('ecru-95', 10);
      }
    }
    &:active {
      background-color: get-color('ecru-95', 20);
    }
  }

  &:disabled,
  &.-disabled {
    cursor: not-allowed;
    opacity: $opacity-30;
  }

  /* modifier */
  &.-active {
    background-color: get-color('ecru-95', 10);
  }

  &.-badge-end {
    padding-right: 0.5625rem;
    padding-left: 0.8125rem;

    > .mm-badge {
      margin-left: 0.5rem;
    }
  }
}

// Media Queries
@use "sass:math";

@mixin breakpoint($point, $type: max) {
  $width: get($grid-breakpoints, $point);
  @if $point == xl {
    $type: min;
  }
  @if $type == max {
    $width: $width - 1px;
  }
  @media (#{$type}-width: $width) {
    @content;
  }
}

// Rem support
$html-font-size: 16px;
@function stripUnit($value) {
  @return math.div($value, $value * 0 + 1);
}
@function rem($pxValue) {
  @return #{math.div(stripUnit($pxValue), stripUnit($html-font-size))}rem;
}

// class used by js to generate mqObj
.custom-mq {
  $mqObj: '';
  @each $name, $value in $grid-breakpoints {
    @if $mqObj != '' {
      $mqObj: $mqObj + '&';
    }
    $mqObj: $mqObj + $name + '=' + $value;
  }
  font-family: $mqObj;
}

@mixin ratio() {
  //Taken from .ratio class from boostrap, made it into a mixin to use it in mediaqueries
  position: relative;
  width: 100%;

  &::before {
    display: block;
    padding-top: var(--#{$variable-prefix}aspect-ratio);
    content: '';
  }

  > * {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

@mixin ratio-2x3 {
  position: relative;
  overflow: hidden;
  padding-top: map-get($aspect-ratios, '2x3');
}

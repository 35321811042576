.unstyled-list {
  list-style: none;
  padding: 0;
  margin: 0;
}

html,
body {
  overscroll-behavior: none;
}

a {
  text-decoration: none;
  color: inherit;
  &:hover {
    color: inherit;
  }
}

.-hide {
  display: none !important;
}

.-invisible {
  visibility: hidden;
}
.-visible {
  visibility: visible;
}

input:invalid ~ .mm-form-message.-invalid:empty,
input.-invalid ~ .mm-form-message.-invalid:empty,
input:valid ~ .mm-form-message.-valid:empty,
input.-valid ~ .mm-form-message.-valid:empty,
textarea:invalid ~ .mm-form-message.-invalid:empty,
textarea.-invalid ~ .mm-form-message.-invalid:empty,
textarea:valid ~ .mm-form-message.-valid:empty,
textarea.-valid ~ .mm-form-message.-valid:empty,
select:invalid ~ .mm-form-message.-invalid:empty,
select.-invalid ~ .mm-form-message.-invalid:empty,
select:valid ~ .mm-form-message.-valid:empty,
select.-valid ~ .mm-form-message.-valid:empty {
  display: none;
}

.mm-form-check {
  flex-wrap: nowrap; //override IM style to fix long label for checkbox
  [type='radio'],
  [type='checkbox'] {
    flex-shrink: 0;
  }
  [type='checkbox'] {
    align-self: flex-start;
  }
  a {
    color: get-color(ecru-50);
  }

  span {
    text-align: left;
  }

  &.-invalid {
    + .mm-form-message.-invalid {
      display: flex;
    }
  }
  //
}

.invalid-feedback {
  @extend .mm-form-message;
  &:empty {
    display: none;
  }
}

.mm-form-message {
  &:empty {
    display: none;
  }
}

.mm-form-input {
  z-index: 0;
  &-invalid {
    + .mm-from-message {
      background-color: lime;
    }
  }
}

.mm-button {
  .mm-icon {
    pointer-events: none;
  }
}

.link-color-gray {
  color: get-color('ecru-50');
}

.toggle-password {
  position: absolute;
  top: 0;
  right: 0.5rem;
  z-index: 2;
  .visible-password {
    display: none;
  }
  &.show-password {
    .visible-password {
      display: flex;
    }
    .hidden-password {
      display: none;
    }
  }
}

.-ecru-50 {
  color: get-color('ecru-50');
}

input[type='number'],
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  appearance: none;
  margin-left: 0;
  margin-right: 0;
}

.lazyload,
.lazyloading {
  opacity: 0;
}
.lazyloaded {
  opacity: 1;
  transition: opacity 300ms;
}

.d-none-checkout {
  display: none !important;
}

/* element */
.mm-form-search-input {
  position: relative;
  width: 100%;

  input {
    width: 100%;
    height: 2.5rem;
    padding: 0 3rem 0 2.5rem;
    color: $ecru-95;
    background-color: get-color('ecru-95', 10);
    background-image: $form-search-input-icon-desktop;
    background-repeat: no-repeat;
    background-position: left 0.75rem center;
    background-size: 1rem 1rem;
    border: 0.125rem solid transparent;
    border-radius: $border-radius-7;
    box-shadow: 0 0 0 0.25rem transparent;
    transition-timing-function: ease-out;
    transition-duration: 75ms;
    transition-property: box-shadow border-color;
    appearance: none;
    @include type('global-control-productive-3');

    &::placeholder {
      color: get-color('ecru-95', 30);
    }

    

    &:hover {
      @include media-hover {
        background-image: $form-search-input-icon-desktop;
      }
    }
    &:focus,
    &:not(:placeholder-shown) {
      background-image: $form-search-input-icon-desktop;
    }

    /* input states */
    &:focus {
      border-color: $info-50;
      outline: 0;
      box-shadow: 0 0 0 4px get-color('info-50', 20);
    }

   

    &:focus ~ .mm-button {
      color: get-color('info-90');
      background-color: get-color('info-50', 10);

      &:not(:disabled) {
        &:hover {
          @include media-hover {
            background-color: get-color('info-50', 20);
          }
        }
        &:active {
          background-color: get-color('info-50', 30);
        }
      }
    }
  }

  /* modifier */
  &.-lg {
    input {
      height: 3rem;
      background-size: 1.125rem 1.125rem;
    }
  }

  .mm-button {
    position: absolute;
    top: 0.25rem;
    right: 0.25rem;
    z-index: 1;

    &.mm-btn-help-area-search-input {
      height: 2rem;
      top: 0.5rem;
      padding: 0 0.5rem;
    }
  }
  
  .mm-btn-accessibility-search {
    height: 2rem;
    top: 0.5rem;
    padding: 0 0.5rem;
    right: 3rem;
  }

  &.mm-form-input.-floating-label label.accessibility-search-label {
    background-color: inherit;
    padding-left: 2.2rem;
  }
  
  &.mm-form-input.-floating-label input.accessibility-search-input:focus~label,
  &.mm-form-input.-floating-label input.accessibility-search-input:not(:placeholder-shown)~label {
    top: 0.25rem;
    background-color: transparent;
    padding-left: 0.2rem;
  }
}

@use "sass:math";

/// Remove the unit of a length
/// @param {Number} $number - Number to remove unit from
/// @return {Number} - Unitless number
@function strip-unit($number) {
  @if type-of($number) == 'number' and not unitless($number) {
    @return math.div($number, $number * 0 + 1);
  }

  @return $number;
}

@mixin fixedSprite($name) {
  $sprite: map-get($sprites, $name);
  background: url($sprite) center no-repeat;
  @if map-has-key($sizes, $name) {
    $size: map-get($sizes, $name);
    width: (strip-unit(map-get($size, width)) * 0.1) * 1rem;
    height: (strip-unit(map-get($size, height)) * 0.1) * 1rem;
  }
  display: inline-block;
}

/* element */
.mm-button {
  position: relative;
  @include button-reset;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: auto;
  height: 2.5rem;
  padding: 0 1rem;
  color: $ecru-95;
  user-select: none;
  background-color: get-color('ecru-95', 10);
  border: 2px solid transparent;
  border-radius: $border-radius-6;
  box-shadow: 0 0 0 4px transparent;

  transition-timing-function: ease-out;
  transition-duration: 75ms;
  transition-property: box-shadow border-color;

  @include type('global-control-productive-3');

  /* states */
  &:focus-visible {
    border-color: $info-50;
    outline: 0;
    box-shadow: 0 0 0 4px get-color('info-50', 20);
  }
  &:not(:disabled) {
    &:hover {
      @include media-hover {
        background-color: get-color('ecru-95', 20);
      }
    }
    &:active {
      background-color: get-color('ecru-95', 30);
    }
  }

  &:disabled,
  &.-disabled {
    cursor: not-allowed;
    opacity: $opacity-30;
  }

  &.-unclickable {
    cursor: default;
  }

  /* modifier */
  &.-rounded {
    border-radius: $border-radius-full;
  }

  &.-expressive {
    @include type('global-control-expressive-3');
  }

  &.-sm {
    height: 1.5rem;
    padding: 0 0.625rem;
    border-radius: $border-radius-4;
    @include type('global-control-productive-1');

    &.-rounded {
      border-radius: $border-radius-full;
    }

    &.-expressive {
      @include type('global-control-expressive-1');
    }
  }

  &.-md {
    height: 2rem;
    padding: 0 0.8125rem;
    border-radius: $border-radius-5;
    @include type('global-control-productive-2');

    &.-rounded {
      border-radius: $border-radius-full;
    }

    &.-expressive {
      @include type('global-control-expressive-2');
    }
  }

  &.-xl {
    height: 3rem;
    padding: 0 1.1875rem;
    border-radius: $border-radius-7;
    @include type('global-control-productive-3');

    &.-rounded {
      border-radius: $border-radius-full;
    }

    &.-expressive {
      @include type('global-control-expressive-3');
    }
  }

  &.-ghost {
    background-color: transparent;

    &:not(:disabled) {
      &:hover {
        @include media-hover {
          background-color: get-color('ecru-95', 10);
        }
      }
      &:active {
        background-color: get-color('ecru-95', 20);
      }
    }
  }

  &.-outline {
    background-color: transparent;
    border-color: $ecru-95;

    &:not(:disabled) {
      &:hover {
        @include media-hover {
          color: $white;
          background-color: $black;
        }
      }
      &:active {
        background-color: inherit;
      }
    }
  }

  &.-solid {
    color: $ecru-05;
    background-color: $ecru-95;

    &:not(:disabled) {
      &:hover {
        @include media-hover {
          background-color: $ecru-90;
        }
      }
      &:active {
        background-color: $ecru-80;
      }
    }
  }

  &.-text {
    background-color: transparent;

    &:not(:disabled) {
      &:hover {
        @include media-hover {
          color: $ecru-90;
          background-color: inherit;
        }
      }
      &:active {
        color: $ecru-80;
        background-color: inherit;
      }
    }
  }

  &.-ecru-05 {
    color: $ecru-05;
    background-color: get-color('ecru-05', 10);

    &:not(:disabled) {
      &:hover {
        @include media-hover {
          background-color: get-color('ecru-05', 20);
        }
      }
      &:active {
        background-color: get-color('ecru-05', 30);
      }
    }

    &.-ghost {
      background-color: transparent;

      &:not(:disabled) {
        &:hover {
          @include media-hover {
            background-color: get-color('ecru-05', 10);
          }
        }
        &:active {
          background-color: get-color('ecru-05', 20);
        }
      }
    }

    &.-outline {
      background-color: transparent;
      border-color: $ecru-05;

      &:not(:disabled) {
        &:hover {
          @include media-hover {
            background-color: inherit;
          }
        }
        &:active {
          background-color: inherit;
        }
      }
    }

    &.-solid-background {
      color: $ecru-05;
      background-color: $ecru-95;

      &:not(:disabled) {
        &:hover {
          @include media-hover {
            background-color: $ecru-80;
          }
        }
        &:active {
          background-color: $ecru-95;
        }
      }
    }

    &.-solid {
      color: $ecru-95;
      background-color: $ecru-05;

      &:not(:disabled) {
        &:hover {
          @include media-hover {
            background-color: $ecru-10;
          }
        }
        &:active {
          background-color: $ecru-20;
        }
      }
    }

    &.-text {
      background-color: transparent;

      &:not(:disabled) {
        &:hover {
          @include media-hover {
            color: $ecru-10;
            background-color: inherit;
          }
        }
        &:active {
          color: $ecru-20;
          background-color: inherit;
        }
      }
    }
  }

  @each $variant in 'info', 'success', 'warning', 'danger' {
    &.-#{$variant} {
      color: get-color(#{$variant + '-90'});
      background-color: get-color(#{$variant + '-50'}, 10);

      &:not(:disabled) {
        &:hover {
          @include media-hover {
            background-color: get-color(#{$variant + '-50'}, 20);
          }
        }
        &:active {
          background-color: get-color(#{$variant + '-50'}, 30);
        }
      }

      &.-ghost {
        color: get-color(#{$variant + '-90'});
        background-color: transparent;

        &:not(:disabled) {
          &:hover {
            @include media-hover {
              background-color: get-color(#{$variant + '-50'}, 10);
            }
          }
          &:active {
            background-color: get-color(#{$variant + '-50'}, 20);
          }
        }
      }

      &.-outline {
        color: get-color(#{$variant + '-70'});
        background-color: transparent;
        border-color: get-color(#{$variant + '-70'});

        &:not(:disabled) {
          &:hover {
            @include media-hover {
              background-color: inherit;
            }
          }
          &:active {
            background-color: inherit;
          }
        }
      }

      &.-solid {
        color: $ecru-05;
        background-color: get-color(#{$variant + '-50'});

        &:not(:disabled) {
          &:hover {
            @include media-hover {
              background-color: get-color(#{$variant + '-70'});
            }
          }
          &:active {
            background-color: get-color(#{$variant + '-80'});
          }
        }
      }

      &.-text {
        background-color: transparent;
        &:not(:disabled) {
          &:hover {
            @include media-hover {
              color: get-color(#{$variant + '-80'});
              background-color: inherit;
            }
          }
          &:active {
            color: get-color(#{$variant + '-70'});
            background-color: inherit;
          }
        }
      }
    }
  }

  &.-icon {
    width: 2.5rem;
    padding: 0;

    &.-sm {
      width: 1.5rem;
    }

    &.-md {
      width: 2rem;
    }

    &.-xl {
      width: 3rem;
    }
  }

  &.-icon,
  &[class^='-icon-'],
  &[class*='-icon-'] {
    > .mm-icon {
      width: 1rem;
      height: 1rem;

      svg *[stroke-width],
      svg *[strokeWidth] {
        stroke-width: 0.08625rem;
      }
    }

    &.-sm {
      > .mm-icon {
        width: 12px;
        height: 12px;

        svg *[stroke-width],
        svg *[strokeWidth] {
          stroke-width: 0.0625rem;
        }
      }
    }

    &.-md {
      > .mm-icon {
        width: 0.875rem;
        height: 0.875rem;

        svg *[stroke-width],
        svg *[strokeWidth] {
          stroke-width: 0.070625rem;
        }
      }
    }

    &.-xl {
      > .mm-icon {
        width: 1.125rem;
        height: 1.125rem;

        svg *[stroke-width],
        svg *[strokeWidth] {
          stroke-width: 0.09375rem;
        }
      }
    }
  }

  &.-icon-start {
    padding-right: 1rem;
    padding-left: 0.75rem;

    > .mm-icon {
      margin-right: 0.5rem;
    }

    &.-sm {
      padding-right: 0.625rem;
      padding-left: 0.375rem;

      > .mm-icon {
        margin-right: 0.375rem;
      }
    }

    &.-lg {
      padding-right: 0.8125rem;
      padding-left: 0.5625rem;
    }

    &.-xl {
      padding-right: 1.1875rem;
      padding-left: 0.9375rem;

      > .mm-icon {
        margin-right: 0.625rem;
      }
    }
  }

  &.-icon-end {
    padding-right: 0.75rem;
    padding-left: 1rem;

    > .mm-icon {
      margin-left: 0.5rem;
    }

    &.-sm {
      padding-right: 0.375rem;
      padding-left: 0.625rem;

      > .mm-icon {
        margin-left: 0.375rem;
      }
    }

    &.-md {
      padding-right: 0.5625rem;
      padding-left: 0.8125rem;
    }

    &.-xl {
      padding-right: 0.9375rem;
      padding-left: 1.1875rem;

      > .mm-icon {
        margin-left: 0.625rem;
      }
    }
  }

  &.-badge-start {
    padding-right: 1rem;
    padding-left: 0.75rem;

    > .mm-badge {
      margin-right: 0.5rem;
    }

    &.-sm {
      padding-right: 0.625rem;
      padding-left: 0.375rem;

      > .mm-badge {
        margin-right: 0.375rem;
      }
    }

    &.-md {
      padding-right: 0.8125rem;
      padding-left: 0.5625rem;
    }

    &.-xl {
      padding-right: 1.1875rem;
      padding-left: 0.9375rem;

      > .mm-badge {
        margin-right: 0.625rem;
      }
    }
  }

  &.-badge-end {
    padding-right: 0.75rem;
    padding-left: 1rem;

    > .mm-badge {
      margin-left: 0.5rem;
    }

    &.-sm {
      padding-right: 0.375rem;
      padding-left: 0.625rem;

      > .mm-badge {
        margin-left: 0.375rem;
      }
    }

    &.-md {
      padding-right: 0.5625rem;
      padding-left: 0.8125rem;
    }

    &.-xl {
      padding-right: 0.9375rem;
      padding-left: 1.1875rem;

      > .mm-badge {
        margin-left: 0.625rem;
      }
    }
  }

  &.-block {
    display: flex;
    width: 100%;
  }

  &.-dot,
  &.-dot-ecru-05,
  &.-dot-danger,
  &.-dot-info,
  &.-dot-warning {
    &::after {
      position: absolute;
      top: 0.375rem;
      right: 0.375rem;
      width: 0.5rem;
      height: 0.5rem;
      content: '';
      background-color: $ecru-95;
      border-radius: $border-radius-full;
    }

    &.-sm::after {
      top: 0.1875rem;
      right: 0.1875rem;
      width: 0.25rem;
      height: 0.25rem;
    }

    &.-md::after {
      top: 0.3125rem;
      right: 0.3125rem;
      width: 0.375rem;
      height: 0.375rem;
    }

    &.-xl::after {
      top: 0.375rem;
      right: 0.375rem;
      width: 0.625rem;
      height: 0.625rem;
    }

    &.-dot-ecru-05::after {
      background-color: $ecru-05;
    }
    &.-dot-info::after {
      background-color: $info-50;
    }
    &.-dot-warning::after {
      background-color: $warning-50;
    }
    &.-dot-danger::after {
      background-color: $danger-50;
    }
  }
}

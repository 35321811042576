// Color system

// default-color-variables
$white: #fff;
$black: #000;

// écrus-color-variables
$ecru-05: hsla(60, 24%, 96%, 1);
$ecru-10: hsla(60, 8%, 91%, 1);
$ecru-20: hsla(60, 5%, 83%, 1);
$ecru-30: hsla(60, 3%, 75%, 1);
$ecru-40: hsla(60, 2%, 64%, 1);
$ecru-50: hsla(60, 1%, 51%, 1);
$ecru-60: hsla(60, 1%, 39%, 1);
$ecru-70: hsla(60, 1%, 28%, 1);
$ecru-80: hsla(60, 1%, 19%, 1);
$ecru-90: hsla(60, 2%, 12%, 1);
$ecru-95: hsla(0, 0%, 7%, 1);
$ecru: $ecru-50;

// info-color-variables
$info-05: hsla(216, 100%, 93%, 1);
$info-10: hsla(216, 100%, 85%, 1);
$info-20: hsla(216, 100%, 78%, 1);
$info-30: hsla(216, 100%, 71%, 1);
$info-40: hsla(216, 100%, 63%, 1);
$info-50: hsla(220, 100%, 56%, 1);
$info-60: hsla(216, 100%, 47%, 1);
$info-70: hsla(216, 100%, 37%, 1);
$info-80: hsla(216, 100%, 28%, 1);
$info-90: hsla(216, 100%, 19%, 1);
$info-95: hsla(216, 100%, 9%, 1);
$info: $info-50;

$info-grey: #767674;

// success-color-variables
$success-05: hsla(155, 45%, 92%, 1);
$success-10: hsla(150, 45%, 84%, 1);
$success-20: hsla(150, 45%, 74%, 1);
$success-30: hsla(150, 45%, 62%, 1);
$success-40: hsla(150, 45%, 54%, 1);
$success-50: hsla(150, 45%, 46%, 1);
$success-60: hsla(150, 45%, 40%, 1);
$success-70: hsla(150, 45%, 32%, 1);
$success-80: hsla(150, 45%, 26%, 1);
$success-90: hsla(150, 45%, 16%, 1);
$success-95: hsla(150, 45%, 8%, 1);
$success: $success-50;

// warning-color-variables
$warning-05: hsla(40, 100%, 90%, 1);
$warning-10: hsla(40, 100%, 83%, 1);
$warning-20: hsla(40, 100%, 74%, 1);
$warning-30: hsla(40, 100%, 69%, 1);
$warning-40: hsla(40, 100%, 61%, 1);
$warning-50: hsla(40, 100%, 50%, 1);
$warning-60: hsla(40, 100%, 42%, 1);
$warning-70: hsla(40, 100%, 34%, 1);
$warning-80: hsla(40, 100%, 26%, 1);
$warning-90: hsla(40, 100%, 18%, 1);
$warning-95: hsla(40, 100%, 10%, 1);
$warning: $warning-50;

// danger-color-variables
$danger-05: hsla(10, 90%, 94%, 1);
$danger-10: hsla(10, 90%, 87%, 1);
$danger-20: hsla(10, 90%, 79%, 1);
$danger-30: hsla(10, 90%, 71%, 1);
$danger-40: hsla(10, 90%, 64%, 1);
$danger-50: hsla(10, 90%, 57%, 1);
$danger-60: hsla(10, 90%, 47%, 1);
$danger-70: hsla(10, 90%, 38%, 1);
$danger-80: hsla(10, 90%, 28%, 1);
$danger-90: hsla(10, 90%, 18%, 1);
$danger-95: hsla(10, 90%, 8%, 1);
$danger: $danger-50;

// colors-map
// This map IS NOT used to generate any utilities.
$colors: (
  'white': $white,
  'black': $black,
  'ecru-05': $ecru-05,
  'ecru-10': $ecru-10,
  'ecru-20': $ecru-20,
  'ecru-30': $ecru-30,
  'ecru-40': $ecru-40,
  'ecru-50': $ecru-50,
  'ecru-60': $ecru-60,
  'ecru-70': $ecru-70,
  'ecru-80': $ecru-80,
  'ecru-90': $ecru-90,
  'ecru-95': $ecru-95,
  'info-05': $info-05,
  'info-10': $info-10,
  'info-20': $info-20,
  'info-30': $info-30,
  'info-40': $info-40,
  'info-50': $info-50,
  'info-60': $info-60,
  'info-70': $info-70,
  'info-80': $info-80,
  'info-90': $info-90,
  'info-95': $info-95,
  'success-05': $success-05,
  'success-10': $success-10,
  'success-20': $success-20,
  'success-30': $success-30,
  'success-40': $success-40,
  'success-50': $success-50,
  'success-60': $success-60,
  'success-70': $success-70,
  'success-80': $success-80,
  'success-90': $success-90,
  'success-95': $success-95,
  'warning-05': $warning-05,
  'warning-10': $warning-10,
  'warning-20': $warning-20,
  'warning-30': $warning-30,
  'warning-40': $warning-40,
  'warning-50': $warning-50,
  'warning-60': $warning-60,
  'warning-70': $warning-70,
  'warning-80': $warning-80,
  'warning-90': $warning-90,
  'warning-95': $warning-95,
  'danger-05': $danger-05,
  'danger-10': $danger-10,
  'danger-20': $danger-20,
  'danger-30': $danger-30,
  'danger-40': $danger-40,
  'danger-50': $danger-50,
  'danger-60': $danger-60,
  'danger-70': $danger-70,
  'danger-80': $danger-80,
  'danger-90': $danger-90,
  'danger-95': $danger-95,
);

// theme-colors-map
// This map IS used to generate any utilities.
$theme-colors: (
  'white': $white,
  'black': $black,
  'info': $info,
  'success': $success,
  'warning': $warning,
  'danger': $danger,
  'ecru-05': $ecru-05,
  'ecru-95': $ecru-95,
);

// The contrast ratio to reach against white, to determine if color changes from "light" to "dark". Acceptable values for WCAG 2.0 are 3, 4.5 and 7.
$min-contrast-ratio: 4.5;

// Customize the light and dark text colors for use in our color contrast function.
$color-contrast-dark: $black;
$color-contrast-light: $white;

// Reset Bootstrap color maps
$grays: ();
$blues: ();
$indigos: ();
$purples: ();
$pinks: ();
$reds: ();
$oranges: ();
$yellows: ();
$greens: ();
$teals: ();
$cyans: ();

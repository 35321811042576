// Typography
//
// stylelint-disable value-keyword-case

$font-family-sans-serif: 'Margiela Sans', 'Helvetica Neue', Arial, sans-serif;
$font-family-serif: 'Margiela Serif', 'Georgia', Times, serif;
$font-family-monospace: 'Margiela Mono', 'Menlo', 'DejaVu Sans Mono',
  'Bitstream Vera Sans Mono', Courier, monospace;

$font-family-base: $font-family-sans-serif;
$font-family-code: $font-family-monospace;

$font-size-root: null;
$font-size-base: 1rem;

$font-weight-base: 400;

$h1-font-size: $font-size-base;
$h2-font-size: $font-size-base;
$h3-font-size: $font-size-base;
$h4-font-size: $font-size-base;
$h5-font-size: $font-size-base;
$h6-font-size: $font-size-base;

$font-sizes: null;

$headings-margin-bottom: auto;
$headings-font-family: inherit;
$headings-font-style: inherit;
$headings-font-weight: inherit;
$headings-line-height: inherit;
$headings-color: inherit;

$display-font-sizes: null;
$display-font-weight: inherit;
$display-line-height: inherit;

$text-muted: $ecru-60;

// Custom types-map
$types: (
  (
    'mm-title-1': (
      base: (
        'font-family': $font-family-serif,
        'font-weight': 400,
        'font-size': 2.5rem,
        'line-height': 1.1,
      ),
      breakpoints: (
        'sm': (
          'font-size': 3.75rem,
          'letter-spacing': -0.25px,
        ),
      ),
    ),
    'mm-title-2': (
      base: (
        'font-family': $font-family-serif,
        'font-weight': 400,
        'font-size': 2.75rem,
        'line-height': 1.1,
        'letter-spacing': -0.1px,
      ),
      breakpoints: (
        'sm': (
          'font-size': 4.75rem,
          'letter-spacing': -0.5px,
        ),
      ),
    ),
    'mm-title-3': (
      base: (
        'font-family': $font-family-serif,
        'font-weight': 400,
        'font-size': 3rem,
        'line-height': 1.1,
        'letter-spacing': -0.25px,
      ),
      breakpoints: (
        'sm': (
          'font-size': 5.75rem,
          'letter-spacing': -1px,
        ),
      ),
    ),
    'mm-title-4': (
      base: (
        'font-family': $font-family-serif,
        'font-weight': 100,
        'font-size': 2rem,
        'line-height': 1.1,
        'letter-spacing': -0.25px,
      ),
      breakpoints: (
        'sm': (
          'font-size': 2rem,
          'letter-spacing': -1px,
          'padding-bottom': 10px,
        ),
      ),
    ),
    'mm-display-1': (
      base: (
        'font-family': $font-family-serif,
        'font-weight': 400,
        'font-size': 1.5rem,
        'line-height': 1.3,
        'letter-spacing': -0.1px,
      ),
      breakpoints: (
        'sm': (
          'font-size': 2rem,
          'letter-spacing': -0.5px,
        ),
      ),
    ),
    'mm-display-2': (
      base: (
        'font-family': $font-family-serif,
        'font-weight': 400,
        'font-size': 2rem,
        'line-height': 1.3,
        'letter-spacing': -0.25px,
      ),
      breakpoints: (
        'sm': (
          'font-size': 2.5rem,
          'letter-spacing': -0.5px,
        ),
      ),
    ),
    'mm-display-3': (
      base: (
        'font-family': $font-family-serif,
        'font-weight': 400,
        'font-size': 2.25rem,
        'line-height': 1.3,
        'letter-spacing': -0.5px,
      ),
      breakpoints: (
        'sm': (
          'font-size': 3rem,
          'letter-spacing': -0.5px,
        ),
      ),
    ),
    'mm-headline-1': (
      base: (
        'font-family': $font-family-serif,
        'font-weight': 400,
        'font-size': 1.375rem,
        'line-height': 1.3,
        'letter-spacing': -0.05px,
      ),
      breakpoints: (
        'sm': (
          'font-size': 1.25rem,
          'letter-spacing': -0.05px,
        ),
      ),
    ),
    'mm-headline-2': (
      base: (
        'font-family': $font-family-serif,
        'font-weight': 400,
        'font-size': 1.625rem,
        'line-height': 1.3,
        'letter-spacing': -0.1px,
      ),
      breakpoints: (
        'sm': (
          'font-size': 1.5rem,
          'letter-spacing': -0.1px,
        ),
      ),
    ),
    'mm-headline-3': (
      base: (
        'font-family': $font-family-serif,
        'font-weight': 400,
        'font-size': 1.875rem,
        'line-height': 1.3,
        'letter-spacing': -0.2px,
      ),
      breakpoints: (
        'sm': (
          'font-size': 1.75rem,
          'letter-spacing': -0.2px,
        ),
      ),
    ),
    'mm-body-1': (
      base: (
        'font-family': $font-family-serif,
        'font-weight': 400,
        'font-size': 1.1875rem,
        'line-height': 1.4,
      ),
      breakpoints: (
        'sm': (
          'font-size': 1.125rem,
        ),
      ),
    ),
    'mm-body-2': (
      base: (
        'font-family': $font-family-serif,
        'font-weight': 400,
        'font-size': 1.3125rem,
        'line-height': 1.4,
        'letter-spacing': -0.1px,
      ),
      breakpoints: (
        'sm': (
          'font-size': 1.25rem,
          'letter-spacing': -0.1px,
        ),
      ),
    ),
    'mm-body-3': (
      base: (
        'font-family': $font-family-serif,
        'font-weight': 400,
        'font-size': 1.4375rem,
        'line-height': 1.4,
        'letter-spacing': -0.15px,
      ),
      breakpoints: (
        'sm': (
          'font-size': 1.375rem,
          'letter-spacing': -0.15px,
        ),
      ),
    ),
    'mm-small-1': (
      base: (
        'font-family': $font-family-serif,
        'font-weight': 400,
        'font-size': 0.8125rem,
        'line-height': 1.4,
        'letter-spacing': -0.05px,
      ),
      breakpoints: (
        'sm': (
          'font-size': 0.75rem,
        ),
      ),
    ),
    'mm-small-2': (
      base: (
        'font-family': $font-family-serif,
        'font-weight': 400,
        'font-size': 0.9375rem,
        'line-height': 1.4,
        'letter-spacing': -0.15px,
      ),
      breakpoints: (
        'sm': (
          'font-size': 0.875rem,
          'letter-spacing': -0.05px,
        ),
      ),
    ),
    'mm-small-3': (
      base: (
        'font-family': $font-family-serif,
        'font-weight': 400,
        'font-size': 1.0625rem,
        'line-height': 1.4,
        'letter-spacing': -0.2px,
      ),
      breakpoints: (
        'sm': (
          'font-size': 1rem,
          'letter-spacing': -0.15px,
        ),
      ),
    ),
    'mm6-title-1': (
      base: (
        'font-family': $font-family-monospace,
        'font-weight': 500,
        'font-size': 2.5rem,
        'line-height': 1.1,
      ),
      breakpoints: (
        'sm': (
          'font-size': 4rem,
        ),
      ),
    ),
    'mm6-title-2': (
      base: (
        'font-family': $font-family-monospace,
        'font-weight': 500,
        'font-size': 2.75rem,
        'line-height': 1.1,
      ),
      breakpoints: (
        'sm': (
          'font-size': 5rem,
        ),
      ),
    ),
    'mm6-title-3': (
      base: (
        'font-family': $font-family-monospace,
        'font-weight': 500,
        'font-size': 3rem,
        'line-height': 1.1,
      ),
      breakpoints: (
        'sm': (
          'font-size': 6rem,
        ),
      ),
    ),
    'mm6-display-1': (
      base: (
        'font-family': $font-family-monospace,
        'font-weight': 375,
        'font-size': 1.5rem,
        'line-height': 1.3,
        'letter-spacing': -0.25px,
      ),
      breakpoints: (
        'sm': (
          'font-size': 1.75rem,
          'letter-spacing': -0.25px,
        ),
      ),
    ),
    'mm6-display-2': (
      base: (
        'font-family': $font-family-monospace,
        'font-weight': 375,
        'font-size': 2rem,
        'line-height': 1.3,
        'letter-spacing': -0.25px,
      ),
      breakpoints: (
        'sm': (
          'font-size': 2.25rem,
          'letter-spacing': -0.25px,
        ),
      ),
    ),
    'mm6-display-3': (
      base: (
        'font-family': $font-family-monospace,
        'font-weight': 375,
        'font-size': 2.25rem,
        'line-height': 1.3,
        'letter-spacing': -0.25px,
      ),
      breakpoints: (
        'sm': (
          'font-size': 2.75rem,
          'letter-spacing': -0.25px,
        ),
      ),
    ),
    'mm6-headline-1': (
      base: (
        'font-family': $font-family-monospace,
        'font-weight': 500,
        'font-size': 1.25rem,
        'line-height': 1.5,
      ),
      breakpoints: (
        'sm': (
          'font-size': 1.125rem,
        ),
      ),
    ),
    'mm6-headline-2': (
      base: (
        'font-family': $font-family-monospace,
        'font-weight': 500,
        'font-size': 1.5rem,
        'line-height': 1.5,
      ),
      breakpoints: (
        'sm': (
          'font-size': 1.375rem,
        ),
      ),
    ),
    'mm6-headline-3': (
      base: (
        'font-family': $font-family-monospace,
        'font-weight': 500,
        'font-size': 1.75rem,
        'line-height': 1.5,
      ),
      breakpoints: (
        'sm': (
          'font-size': 1.625rem,
        ),
      ),
    ),
    'mm6-body-1': (
      base: (
        'font-family': $font-family-monospace,
        'font-weight': 375,
        'font-size': 0.9375rem,
        'line-height': 1.8,
      ),
      breakpoints: (
        'sm': (
          'font-size': 0.875rem,
        ),
      ),
    ),
    'mm6-body-2': (
      base: (
        'font-family': $font-family-monospace,
        'font-weight': 375,
        'font-size': 1.0625rem,
        'line-height': 1.75,
      ),
      breakpoints: (
        'sm': (
          'font-size': 1rem,
        ),
      ),
    ),
    'mm6-body-3': (
      base: (
        'font-family': $font-family-monospace,
        'font-weight': 375,
        'font-size': 1.1875rem,
        'line-height': 1.7,
      ),
      breakpoints: (
        'sm': (
          'font-size': 1.125rem,
        ),
      ),
    ),
    'mm6-small-1': (
      base: (
        'font-family': $font-family-monospace,
        'font-weight': 375,
        'font-size': 0.625rem,
        'line-height': 1.6,
      ),
      breakpoints: (
        'sm': (
          'font-size': 0.5625rem,
        ),
      ),
    ),
    'mm6-small-2': (
      base: (
        'font-family': $font-family-monospace,
        'font-weight': 375,
        'font-size': 0.75rem,
        'line-height': 1.6,
      ),
      breakpoints: (
        'sm': (
          'font-size': 0.6875rem,
        ),
      ),
    ),
    'mm6-small-3': (
      base: (
        'font-family': $font-family-monospace,
        'font-weight': 375,
        'font-size': 0.875rem,
        'line-height': 1.6,
      ),
      breakpoints: (
        'sm': (
          'font-size': 0.8125rem,
        ),
      ),
    ),
    'global-title-1': (
      base: (
        'font-family': $font-family-sans-serif,
        'font-weight': 600,
        'font-size': 2.5rem,
        'line-height': 1.1,
      ),
      breakpoints: (
        'sm': (
          'font-size': 4rem,
        ),
      ),
    ),
    'global-title-2': (
      base: (
        'font-family': $font-family-sans-serif,
        'font-weight': 700,
        'font-size': 2.75rem,
        'line-height': 1.1,
      ),
      breakpoints: (
        'sm': (
          'font-size': 5rem,
        ),
      ),
    ),
    'global-title-3': (
      base: (
        'font-family': $font-family-sans-serif,
        'font-weight': 700,
        'font-size': 3rem,
        'line-height': 1.1,
      ),
      breakpoints: (
        'sm': (
          'font-size': 6rem,
        ),
      ),
    ),
    'global-display-1': (
      base: (
        'font-family': $font-family-sans-serif,
        'font-weight': 600,
        'font-size': 1.5rem,
        'line-height': 1.3,
      ),
      breakpoints: (
        'sm': (
          'font-size': 1.75rem,
        ),
      ),
    ),
    'global-display-2': (
      base: (
        'font-family': $font-family-sans-serif,
        'font-weight': 600,
        'font-size': 2rem,
        'line-height': 1.3,
      ),
      breakpoints: (
        'sm': (
          'font-size': 2.25rem,
        ),
      ),
    ),
    'global-display-3': (
      base: (
        'font-family': $font-family-sans-serif,
        'font-weight': 600,
        'font-size': 2.25rem,
        'line-height': 1.3,
      ),
      breakpoints: (
        'sm': (
          'font-size': 2.75rem,
        ),
      ),
    ),
    'global-headline-1': (
      base: (
        'font-family': $font-family-sans-serif,
        'font-weight': 500,
        'font-size': 1.25rem,
        'line-height': 1.5,
      ),
      breakpoints: (
        'sm': (
          'font-size': 1.125rem,
        ),
      ),
    ),
    'global-headline-2': (
      base: (
        'font-family': $font-family-sans-serif,
        'font-weight': 500,
        'font-size': 1.5rem,
        'line-height': 1.5,
      ),
      breakpoints: (
        'sm': (
          'font-size': 1.375rem,
        ),
      ),
    ),
    'global-headline-3': (
      base: (
        'font-family': $font-family-sans-serif,
        'font-weight': 500,
        'font-size': 1.75rem,
        'line-height': 1.5,
      ),
      breakpoints: (
        'sm': (
          'font-size': 1.625rem,
        ),
      ),
    ),
    'global-body-1': (
      base: (
        'font-family': $font-family-sans-serif,
        'font-weight': 375,
        'font-size': 0.9375rem,
        'line-height': 1.8,
      ),
      breakpoints: (
        'sm': (
          'font-size': 0.875rem,
        ),
      ),
    ),
    'global-body-2': (
      base: (
        'font-family': $font-family-sans-serif,
        'font-weight': 375,
        'font-size': 1.0625rem,
        'line-height': 1.75,
      ),
      breakpoints: (
        'sm': (
          'font-size': 1rem,
        ),
      ),
    ),
    'global-body-3': (
      base: (
        'font-family': $font-family-sans-serif,
        'font-weight': 375,
        'font-size': 1.125rem,
        'line-height': 1.7,
      ),
      breakpoints: (
        'sm': (
          'font-size': 1.125rem,
        ),
      ),
    ),
    'global-small-1': (
      base: (
        'font-family': $font-family-sans-serif,
        'font-weight': 375,
        'font-size': 0.6875rem,
        'line-height': 1.6,
        'letter-spacing': 0.1px,
      ),
      breakpoints: (
        'sm': (
          'font-size': 0.625rem,
          'letter-spacing': 0.1px,
        ),
      ),
    ),
    'global-small-2': (
      base: (
        'font-family': $font-family-sans-serif,
        'font-weight': 375,
        'font-size': 0.8125rem,
        'line-height': 1.6,
        'letter-spacing': 0.1px,
      ),
      breakpoints: (
        'sm': (
          'font-size': 0.75rem,
          'letter-spacing': 0.1px,
        ),
      ),
    ),
    'global-small-3': (
      base: (
        'font-family': $font-family-sans-serif,
        'font-weight': 375,
        'font-size': 0.9375rem,
        'line-height': 1.6,
        'letter-spacing': 0.1px,
      ),
      breakpoints: (
        'sm': (
          'font-size': 0.875rem,
          'letter-spacing': 0.1px,
        ),
      ),
    ),
    'global-caption-1': (
      base: (
        'font-family': $font-family-sans-serif,
        'font-weight': 500,
        'font-size': 0.625rem,
        'line-height': 1.6,
      ),
    ),
    'global-caption-2': (
      base: (
        'font-family': $font-family-sans-serif,
        'font-weight': 500,
        'font-size': 0.75rem,
        'line-height': 1.6,
      ),
    ),
    'global-caption-3': (
      base: (
        'font-family': $font-family-sans-serif,
        'font-weight': 500,
        'font-size': 0.875rem,
        'line-height': 1.6,
      ),
    ),
    'global-callout-1': (
      base: (
        'font-family': $font-family-sans-serif,
        'font-weight': 700,
        'font-size': 0.8125rem,
        'line-height': 1.6,
        'letter-spacing': 1px,
        'text-transform': uppercase,
      ),
      breakpoints: (
        'sm': (
          'font-size': 0.6875rem,
        ),
      ),
    ),
    'global-callout-2': (
      base: (
        'font-family': $font-family-sans-serif,
        'font-weight': 700,
        'font-size': 0.875rem,
        'line-height': 1.6,
        'letter-spacing': 1px,
        'text-transform': uppercase,
      ),
      breakpoints: (
        'sm': (
          'font-size': 0.75rem,
        ),
      ),
    ),
    'global-callout-3': (
      base: (
        'font-family': $font-family-sans-serif,
        'font-weight': 700,
        'font-size': 0.9375rem,
        'line-height': 1.6,
        'letter-spacing': 1px,
        'text-transform': uppercase,
      ),
      breakpoints: (
        'sm': (
          'font-size': 0.8125rem,
        ),
      ),
    ),
    'global-control-productive-1': (
      base: (
        'font-family': $font-family-sans-serif,
        'font-weight': 500,
        'font-size': 0.875rem,
        'line-height': 1,
      ),
      breakpoints: (
        'sm': (
          'font-size': 0.75rem,
        ),
      ),
    ),
    'global-control-productive-2': (
      base: (
        'font-family': $font-family-sans-serif,
        'font-weight': 500,
        'font-size': 0.9375rem,
        'line-height': 1,
      ),
      breakpoints: (
        'sm': (
          'font-size': 0.8125rem,
        ),
      ),
    ),
    'global-control-productive-3': (
      base: (
        'font-family': $font-family-sans-serif,
        'font-weight': 500,
        'font-size': 1rem,
        'line-height': 1,
      ),
      breakpoints: (
        'sm': (
          'font-size': 0.875rem,
        ),
      ),
    ),
    'global-control-expressive-1': (
      base: (
        'font-family': $font-family-sans-serif,
        'font-weight': 700,
        'font-size': 0.8125rem,
        'line-height': 1,
        'letter-spacing': 1px,
        'text-transform': uppercase,
      ),
      breakpoints: (
        'sm': (
          'font-size': 0.6875rem,
        ),
      ),
    ),
    'global-control-expressive-2': (
      base: (
        'font-family': $font-family-sans-serif,
        'font-weight': 700,
        'font-size': 0.875rem,
        'line-height': 1,
        'letter-spacing': 1px,
        'text-transform': uppercase,
      ),
      breakpoints: (
        'sm': (
          'font-size': 0.75rem,
        ),
      ),
    ),
    'global-control-expressive-3': (
      base: (
        'font-family': $font-family-sans-serif,
        'font-weight': 700,
        'font-size': 0.9375rem,
        'line-height': 1,
        'letter-spacing': 1px,
        'text-transform': uppercase,
      ),
      breakpoints: (
        'sm': (
          'font-size': 0.8125rem,
        ),
      ),
    ),
    'global-label-expressive-1': (
      base: (
        'font-family': $font-family-sans-serif,
        'font-weight': 700,
        'font-size': 0.5rem,
        'line-height': normal,
        'letter-spacing': 1px,
        'text-transform': uppercase,
      ),
    ),
    'global-label-expressive-2': (
      base: (
        'font-family': $font-family-sans-serif,
        'font-weight': 600,
        'font-size': 0.5625rem,
        'line-height': normal,
        'letter-spacing': 1px,
        'text-transform': uppercase,
      ),
    ),
    'global-label-expressive-3': (
      base: (
        'font-family': $font-family-sans-serif,
        'font-weight': 600,
        'font-size': 0.625rem,
        'line-height': normal,
        'letter-spacing': 1px,
        'text-transform': uppercase,
      ),
    ),
    'global-label-productive-1': (
      base: (
        'font-family': $font-family-sans-serif,
        'font-weight': 500,
        'font-size': 0.5625rem,
        'line-height': normal,
      ),
    ),
    'global-label-productive-2': (
      base: (
        'font-family': $font-family-sans-serif,
        'font-weight': 500,
        'font-size': 0.625rem,
        'line-height': normal,
      ),
    ),
    'global-label-productive-3': (
      base: (
        'font-family': $font-family-sans-serif,
        'font-weight': 500,
        'font-size': 0.6875rem,
        'line-height': normal,
      ),
    ),
  )
);
